<template>
  <div class="loginapp">
    <div class="logintop">
      <img src="~@/assets/login/logo.png">
    </div>
    <div class="logincon">
      <div class="coninfo">
        <div class="infoimg">
          <img src="~@/assets/login/logintu.png">
        </div>
        <div class="infowenzi">
          <div class="formdiv">
            <div class="huanyin">欢迎登录</div>
            <el-form
              ref="ruleForm"
              :model="ruleForm"
            >
              <div class="inputdiv">
                <el-input
                  v-model="ruleForm.username"
                  type="text"
                  auto-complete="off"
                  placeholder="手机号|邮箱"
                  prefix-icon="el-icon-user"
                />
              </div>
              <div class="inputdiv">
                <el-input
                  v-model="ruleForm.password"
                  type="password"
                  auto-complete="off"
                  placeholder="登录密码"
                  prefix-icon="el-icon-lock"
                />
                <el-checkbox
                  v-model="checked"
                  class="radio"
                  style="margin-top: 10px;margin-left: 10px;"
                >记住密码</el-checkbox>
              </div>
              <div class="dengru">
                <el-button
                  type="primary"
                  @click="userLogin()"
                >登 录</el-button>
                <span class="leftzhu" @click="goto('/register')">免费注册账号</span>
                <span class="rtgthzhu" @click="goto('/reset')">忘记密码？</span>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="loginbottom">
      <div class="bottominfo">
        <el-row class="elrow">
          <el-col :span="4"
            ><div class="elcol" style="border-left: 0px;">首页</div></el-col
          >
          <el-col :span="5"><div class="elcol">佳裕达商城</div></el-col>
          <el-col :span="5"><div class="elcol">服务介绍</div></el-col>
          <el-col :span="5"><div class="elcol">业务网点</div></el-col>
          <el-col :span="5"><div class="elcol">关于我们</div></el-col>
        </el-row>
      </div>
      <div class="wangzhan">http://www.jayud.com/products/qctgfw</div>
    </div> -->
  </div>
</template>

<script>
import { login } from '@/api/user.js';
export default {
  data() {
    return {
      ruleForm: {
        username: '',
        password: ''
      },
      checked: ''
    };
  },
  created() {
    this.init();
  },
  mounted() {

  },
  methods: {
    init() {
      if (sessionStorage.isLogin) {
        this.$router.replace({ path: '/' });
      }
      localStorage.checked ? this.checked = JSON.parse(localStorage.checked) : '';
      localStorage.google_name ? this.ruleForm.username = atob(localStorage.google_name) : '';
      localStorage.google_word ? this.ruleForm.password = atob(localStorage.google_word) : '';
    },
    userLogin() { // 登录
      login(this.ruleForm).then(result => {
        if (result.status == 200) {
          sessionStorage.isLogin = 'true';
          this.$router.push({ path: '/home' });
          if (this.checked) {
            localStorage.checked = this.checked;
            localStorage.google_name = btoa(this.ruleForm.username);
            localStorage.google_word = btoa(this.ruleForm.password);
          } else {
            localStorage.removeItem('checked');
            localStorage.removeItem('google_name');
            localStorage.removeItem('google_word');
          }
        }
      }).catch(error => {
        this.$message.error(error.data.msg);
      });
    },
    goto(route) {
      this.$router.push({ path: route });
    }
  }
};
</script>

<style scoped>
.loginapp {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  position: relative;
}
.logintop {
  width: 100%;
  height: 80px;
  background-color: #fff;
}
.logintop img {
  margin-top: 20px;
  margin-left: 150px;
}
.loginbottom {
  width: 100%;
  height: 150px;
  overflow:hidden;
}
.bottominfo {
  width: 800px;
  height: auto;
  margin: 0 auto;
}
.elrow {
  width: 100%;
  margin: 0 auto;
  color: #333333;
  line-height: 16px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  padding:0;
  margin:30px 0;
}
.elrow .elcol {
  border-left: 1px solid #333;
  text-align: center;
  height: 15px;
}
.wangzhan {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  text-align: center;
}
.logincon {
  width: 100%;
  height: calc(100% - 230px);
  display: flex;
  flex-direction: row; /* 子元素横向排列 */
  justify-content: center; /* 相对父元素水平居中 */
  align-items: center; /*  子元素相对父元素垂直居中 */
  background: url("~@/assets/bg.png") no-repeat center top;
  min-height: 684px;
}
.coninfo {
  width: 1200px;
  height: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: row; /* 子元素横向排列 */
  justify-content: center; /* 相对父元素水平居中 */
  align-items: center; /*  子元素相对父元素垂直居中 */
}
.infoimg {
  width: calc(100% - 400px);
}
.infowenzi {
  width: 400px;
  height: 440px;

  border: 1px solid rgba(225, 225, 225, 0.2);
  border-radius: 6px;
  display: flex;
  flex-direction: row; /* 子元素横向排列 */
  justify-content: center; /* 相对父元素水平居中 */
  align-items: center; /*  子元素相对父元素垂直居中 */
}
.formdiv {
  width: 370px;
  height: 410px;
  background-color: #fff;
}
.huanyin {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  text-align: center;
  line-height: 90px;
}
.inputdiv {
  width: 80%;
  margin: 0 auto;
  margin-top: 30px;
}
.dengru {
  width: 300px;
  height: 50px;
  margin: 0 auto;
  margin-top: 30px;
}
.dengru .el-button {
  width: 100%;
  background: #3874ee;
  border-radius: 25px;
  background-image: linear-gradient(#5BA8FF,#2A70FF);
}
.leftzhu {
  cursor:pointer;
  font-size: 10px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  margin-top: 20px;
  margin-left:10px;
  float: left;
}
.rtgthzhu {
  cursor:pointer;
  font-size: 10px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  margin-right:10px;
  margin-top: 20px;
  float: right;
}
.radio {
  font-size: 10px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}
</style>
